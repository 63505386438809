const isLarge = window.matchMedia('(min-width: 960px)').matches ? true : false;
const foParent = document.querySelector('#header-contacts');
const headerFlyouts = foParent.querySelectorAll('.flyout-button-wrap');
const defaultOpenFO = headerFlyouts[0];

//Get whichever flyout is open
const findOpen = () => foParent.querySelector('.open');

//Add the class
const add_class_on_demand = (elm) => {
	elm.classList.add('open');
};

//Remove the class
const remove_class_on_demand = () => {
	let currentOpen = findOpen();
	if(currentOpen) {
		currentOpen.classList.remove('open');
	}
};

//Handle the class changes
const handleFlyouts = (e) => {
	e.preventDefault();
	let openFlyout = findOpen();
	let target = e.currentTarget.parentNode;

	if (openFlyout && openFlyout === target) {
		remove_class_on_demand();
	} else if (!openFlyout) {
		add_class_on_demand(target);
	} else {
		if (openFlyout) {
			remove_class_on_demand();
			add_class_on_demand(target);
		}
	}
};

//Open default on Large
window.addEventListener('load', (event) => {
	if(isLarge) {
		add_class_on_demand(defaultOpenFO);
		// console.log(isLarge);
	}
});

//Open on click
headerFlyouts.forEach((flyout) => {
	let trigger = flyout.querySelector('.btn-icon');
	trigger.addEventListener('click', handleFlyouts, false);
});

//Handle the Scrolling
let scrollpos = window.scrollY;
const main = document.querySelector('.site-main');
const triggerPos = 50;

window.addEventListener('scroll', () => {
	scrollpos = window.scrollY;

	if (scrollpos >= triggerPos) {
		remove_class_on_demand();		
	} else if (scrollpos === 0 && isLarge) {
		add_class_on_demand(defaultOpenFO);
	}
});