// import { Dropdown, Collapse, Modal } from 'bootstrap';
import { Offcanvas } from 'bootstrap';
import { Modal } from 'bootstrap';
import { Dropdown } from 'bootstrap';

//Components
import '../scss/theme.scss';
// import './components/sticky-header.js';
import './components/header-contacts.js';

//Images
import '../image/bellwether-logo.svg';
import '../image/spacer.gif';
import '../image/contact-mountain.png';
import '../image/bg-ram-mountains.jpg';
// import '../image/clouds-header.png';
import '../image/bg-lander.png';
import '../image/bg-lines.png';
import '../image/bg-lander-location.png';
import '../image/bg-lander-locations_r1.png';
import '../image/bg-lander-locations_r1.webp';
import '../image/facebook.png'; 
import '../image/twitter.png'; 
import '../image/instagram.png'; 
import '../image/linkedin.png'; 

window.addEventListener('DOMContentLoaded', function() {
	//SVG INJECTOR
	const elementsToInject = document.querySelectorAll('.inject-me');
	const injectorOptions = {
		evalScripts: 'once'
	};
	let injector = new SVGInjector(injectorOptions);
	if (elementsToInject.length) {
		injector.inject(elementsToInject);
	}
}, false);